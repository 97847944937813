import React from 'react';
import HeroSection from '../component/HeroSection';
import careerimg from '../assets/Herosection/hero-conatct-img.png'
import Forms from '../component/Forms';


const Contact = () => {


  return (
    <>
      <HeroSection heading="Contact Us" subheading="Get in Touch: Let's Collaborate to Drive Your Business Forward" heroImg={careerimg} />

      <div class="flex justify-center items-center">
        <div class="bg-gray-200 p-4 flex">
          <div class="bg-blue-200 p-4 w-[500px]">
            <ul>
              <li>Phone :  +91 6299228862</li>
              <li>Email : info@vittipanya.com</li>
              <li>Address : Bodhi Chowk, Road No. 1, New-Shahpur, Jaganpura Road, Patna</li>
              <li></li>
            </ul>
          </div>
          <div class="bg-green-200 p-4 w-[500px]">
            <Forms />
          </div>
        </div>
      </div>

    </>
  )
}

export default Contact

