import React, { useRef } from 'react';
import CustomButton from './CustomButton';

import emailjs from '@emailjs/browser';

function Forms() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, {
                publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_ID,
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                },
                (error) => {
                    console.log('FAILED...', error.text);
                }
            );
    };

    return (
        <form ref={form} onSubmit={sendEmail}>
            <div className="mb-4">
                <h2 className="text-2xl">CONTACT US</h2>
                <label className="block text-[#1d1d1d] text-sm mb-2" htmlFor="fullName">
                    Full Name
                </label>
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="fullName"
                    type="text"
                    placeholder="Enter your Full Name"
                    name="user_name"
                />
            </div>
            <div className="mb-4">
                <label className="block text-[#1d1d1d] text-sm mb-2" htmlFor="emailId">
                    Email Id
                </label>
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="emailId"
                    type="email"
                    placeholder="Enter your Email Id"
                    name="user_email"
                />
            </div>
            <div className="mb-4">
                <label className="block text-[#1d1d1d] text-sm mb-2" htmlFor="phoneNumber">
                    Phone Number
                </label>
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="phoneNumber"
                    type="text"
                    placeholder="Enter your Phone Number"
                    name="user_phone"
                />
            </div>
            <div className="mb-4">
                <label htmlFor="Services" className="block text-[#1d1d1d] text-sm mb-2">
                    Select Services
                </label>
                <select
                    id="Services"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                    <option defaultValue>Services</option>
                    <option value="Seo">SEO</option>
                    <option value="Social Media Marketing Services">Social Media Marketing Services</option>
                    <option value="Content Marketing">Content Marketing</option>
                    <option value="Website Design">Website Design</option>
                    <option value="Logo Design">Logo Design</option>
                    <option value="Google Ads">Google Ads</option>
                </select>
            </div>
            <div className="flex items-center justify-center mt-5">
                <CustomButton
                    type="submit"
                    text="Submit"
                    className="text-white bg-[#4364ad] hover:bg-[#1d1d1d] focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 w-[150px] text-center mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 font-poppins"
                />
            </div>
        </form>
    );
}

export default Forms;
