import React, { useState } from 'react'

const CollapsibleBox = ({data}) => {

    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleToggle = () => {
        setIsCollapsed(!isCollapsed);
    };

  return (
        <div className="mx-auto mt-4 p-4 w-[100%] border rounded-md">
            <div className="flex justify-between items-center">
                <span className="text-lg font-semibold font-poppinsRegular">{data.question}</span>
                <button
                onClick={handleToggle}
                className="focus:outline-none transition transform hover:scale-110"
                >
                {isCollapsed ? (
                    <span className="text-4xl font-semibold">&#43;</span>
                ) : (
                    <span className="text-4xl font-semibold">&#8722;</span>
                )}
                </button>
            </div>
            {!isCollapsed && (
                <div className="mt-4">
                {/* Your content goes here */}
                <p className='font-poppinsRegular'>{data.answer}</p>
                </div>
            )}
        </div>
  )
}

export default CollapsibleBox