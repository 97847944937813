import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

import enquireImg from '../assets/conatct-enquire-img.jpg';
import CustomButton from './CustomButton';

const Modal = ({ isOpen, onClose }) => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_t92s6kc', 'template_lkhrc0l', form.current, {
        publicKey: 'GYihnMpTvOrJrcV57',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  if (!isOpen) return null;

  return (
    <div className="fixed z-99999 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <div className="fixed inset-0 transition-opacity" onClick={onClose}>
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>
        <div className="relative w-[500px] hidden lg:block">
          <div className="">
            <img src={enquireImg} alt="popup" className='h-[423px] mx-[12px] rounded-l' />
          </div>
        </div>
        <div className="relative bg-white rounded-lg w-96 p-6">
          <button className="absolute top-0 right-0 mt-2 mr-2" onClick={onClose}>
            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
          <form ref={form} onSubmit={sendEmail}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="fullName">
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="fullName"
                type="text"
                placeholder="Enter your Full Name"
                name="user_name"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="emailId">
                Email Id
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="emailId"
                type="email"
                placeholder="Enter your Email Id"
                name="user_email"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phoneNumber">
                Phone Number
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="phoneNumber"
                type="text"
                placeholder="Enter your Phone Number"
                name="user_phone"
              />
            </div>
            <div className="mb-4">
              <label for="Services" className="block text-gray-700 text-sm font-bold mb-2">Select Services</label>
              <select id="Services" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option selected>Services</option>
                <option value="Seo">SEO</option>
                <option value="Social Media Markrting Services">Social Media Markrting Services</option>
                <option value="Content Marketing">Content Marketing</option>
                <option value="Website Design">Website Design</option>
                <option value="Logo Design">Logo Design</option>
                <option value="Google Ads">Google Ads</option>
              </select>
            </div>
            <div className="flex items-center justify-between mt-5">
              <CustomButton
                type="submit"
                text="Submit"
                className="text-white bg-[#4364ad] hover:bg-[#1d1d1d] focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 w-[150px] text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 font-poppins"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Modal;
