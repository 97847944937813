import React from 'react'

import Router from '../Router'
import Footer from '../component/Footer'
import MegaMenuDefault from '../component/MegaMenuDefault'




const Layout = () => {
  return (
    <div>
      <MegaMenuDefault />
      {/* <Navbar /> */}
      <Router />
      <Footer />
    </div>
  )
}

export default Layout