import React from 'react'
import './HeroSection.css'


function HeroSection({ heading, subheading, heroImg }) {
    return (

        <div className='herobg w-full h-[90vh] flex items-center justify-center object-cover px-4 py-20'>
            <div className="container mx-auto flex flex-col lg:flex-row items-center lg:mt-24">
                <div className="lg:w-1/2 lg:pr-10 ">
                    <div className='lg:px-20 sm:px-0'>
                        <h1 className="text-3xl lg:text-5xl text-[#fff] font-bold text-center lg:text-left mb-4">{heading}</h1>
                        <p className="text-lg lg:text-xl text-[#fff] font-medium text-center lg:text-left mb-4">{subheading}</p>
                        {/* Add your content here */}
                    </div>
                </div>
                <div className="lg:w-1/2 mt-6 lg:mt-0">
                    <img src={heroImg} alt="" className="w-[600px] h-auto" />
                </div>
            </div>
        </div>



    );
}

export default HeroSection