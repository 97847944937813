import './App.css';
import Layout from './pages/Layout';

function App() {
  return (
    <>
     <Layout/>
    </>
  );
}

export default App;
