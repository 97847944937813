import React from 'react';
import './ClientsSection.css';
import client1 from '../assets/clients/client1.png';
import client2 from '../assets/clients/client2.png';
import client3 from '../assets/clients/client3.png';
import client4 from '../assets/clients/client4.png';
import client5 from '../assets/clients/client5.jpg';
import client6 from '../assets/clients/client6.png';
import client7 from '../assets/clients/client7.png';
import client8 from '../assets/clients/client8.png';

const clients = [
  { id: 1, name: 'Client 1', imgUrl: client1 },
  { id: 2, name: 'Client 2', imgUrl: client2 },
  { id: 3, name: 'Client 3', imgUrl: client3 },
  { id: 4, name: 'Client 4', imgUrl: client4 },
  { id: 5, name: 'Client 5', imgUrl: client5 },
  { id: 5, name: 'Client 6', imgUrl: client6 },
  { id: 5, name: 'Client 7', imgUrl: client7 },
  { id: 5, name: 'Client 8', imgUrl: client8 },
  
];

const ClientsSection = () => {
  return (
    <div className='clients-section'>
      <div className='w-95 h-auto'>
        <h1 className="text-3xl text-center font-bold text-[#234190]">Our Clients</h1>
        <p className='text-center text-md font-normal lg:w-[80%] lg:mx-auto font-medium mt-[1rem]'>
          We are proud to have worked with a diverse range of clients who have trusted us to help them achieve their goals.
          Here are some of our esteemed clients:
        </p>
        <div className='clients-wrapper'>
          {clients.map(client => (
            <div key={client.id} className="client-card">
              <div className="client-card-inner" style={{ backgroundImage: `url(${client.imgUrl})` }}>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClientsSection;
