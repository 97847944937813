import React from 'react';
import checkboxIcon from "../assets/checkbox.png";
import aboutUsVector1 from "../assets/aboutUsVector1.jpg";
import aboutUsVector4 from "../assets/aboutusVector4.svg";
import HeroSection from '../component/HeroSection';
import careerimg from '../assets/Herosection/hero-about-img.webp'


const About = () => {
  return (
    <div className='w-[100%]'>

      {/* header image box */}
      <HeroSection heading="About Us" subheading="Discover Our Story: Transforming Visions into Technological Realities" heroImg={careerimg} />

      {/* We are vittipanya */}
      <div className='w-[100%] h-max-content pt-[2rem] pb-[1rem] flex items-center justify-center'>
        <div className='h-[95%] w-[82%] flex md:flex-row xs:flex-col-reverse items-center justify-between'>
          <div className='md:h-[100%] md:w-[49%] p-[1rem] xs:h-[50%] xs:w-[100%]'>
            <p className='text-xl font-semibold font-poppinsRegular'>We are <span className='text-[#d18812]'>Vittipanya</span></p>
            <p className='md:w-[80%] xs:w-[100%] mt-[1rem] font-poppinsRegular lg:leading-8 md:leading-4'>VittiPanya, is one of the best digital marketing service provider, specializes in bridging the online gap for educational institutions, healthcare professionals, and businesses. Our comprehensive range of services ensures effective online integration, connecting colleges with students and doctors with patients.</p>
            <div className='mt-[2rem] h-[40%] w-[100%] md:block lg:flex flex-wrap justify-between'>
              <div className='lg:w-[49%] lg:h-[100%] md:w-[100%] md:h-[50%]'>
                <div className='h-[50%] w-[100%] flex items-center gap-[10px]'><img src={checkboxIcon} className='h-[30px] w-[30px]' alt="checkbox" /><p className='font-poppinsRegular'>Strategic Digital Planning</p></div>
                <div className='h-[50%] w-[100%] flex items-center mt-[0.5rem] gap-[10px]'><img src={checkboxIcon} className='h-[30px] w-[30px]' alt="checkbox" /><p className='font-poppinsRegular'>Client-Centric Approach</p></div>
              </div>
              <div className='lg:w-[49%] lg:h-[100%] md:w-[100%] md:h-[50%]'>
                <div className='h-[50%] w-[100%] flex items-center gap-[10px]'><img src={checkboxIcon} className='h-[30px] w-[30px]' alt="checkbox" /><p className='font-poppinsRegular'>Proven Track Record</p></div>
                <div className='h-[50%] w-[100%] flex items-center mt-[0.5rem] gap-[10px]'><img src={checkboxIcon} className='h-[30px] w-[30px]' alt="checkbox" /><p className='font-poppinsRegular'>100% Business growth</p></div>
              </div>
            </div>
          </div>
          <div className='md:h-[100%] md:w-[49%] xs:w-[100%] xs:h-[50%]'>
            <img src={aboutUsVector1} alt="Vector" className='h-[100%] w-[100%]' />
          </div>
        </div>
      </div>

      {/* Mission & Vison */}
      <div className='h-max-content w-[100%] p-[2rem] bg-[#e5e5e5] mt-[1rem] flex items-center justify-center'>
        <div className='h-[90%] w-[84%] border-2'>
          <div className='w-[100%] bg-[white] h-[30%] shadow-lg rounded-md'>
            <p className='bg-[#d18812] text-lg font-poppinsRegular font-bold text-center'>Mission</p>
            <div className='p-[1rem]'>
              <p className='font-poppinsRegular leading-8'>Empowering businesses to thrive in the online landscape, we pioneer creative digital solutions that elevate their presence, fostering distinctive growth in the digital realm. Our vision is to be recognized as a leading digital marketing company in India, delivering unparalleled excellence to our clients.</p>
            </div>
          </div>
          <div className='md:w-[80%] xs:w-[100%] bg-[white] mt-[1rem] h-[35%] shadow-lg rounded-md '>
            <p className='bg-[#d18812] text-lg font-poppinsRegular font-bold text-center'>Vision</p>
            <div className='p-[1rem]'>
              <p className='font-poppinsRegular'><span className='font-semibold '>Digital Leaders:</span> Be the go-to team for helping businesses use the latest tech to grow and succeed.</p>
              <p className='font-poppinsRegular mt-[0.3rem]'><span className='font-semibold'>Happy Clients:</span> Make sure every client is super happy with the results, building strong and lasting relationships.</p>
              <p className='font-poppinsRegular mt-[0.3rem]'><span className='font-semibold'>Always Innovating:</span>  Keep coming up with cool and new ideas to help businesses stay ahead online.</p>
              <p className='font-poppinsRegular mt-[0.3rem]'><span className='font-semibold'>Global Friends:</span>   Help businesses all around the world, making a positive impact on everyone we work with.</p>
            </div>
          </div>
          <div className='md:w-[60%] xs:w-[100%] bg-[white] mt-[1rem] h-[30%] shadow-lg rounded-md'>
            <p className='bg-[#d18812] text-lg font-poppinsRegular font-bold text-center'>USP</p>
            <div className='p-[1rem]'>
              <p className='font-poppinsRegular'>At VittiPanya Digital, we make your brand stand out with effective digital solutions that ensure big recognition, all at a small investment—because we believe in creating a big identity for your business without breaking the bank</p>
            </div>
          </div>
        </div>
      </div>

      {/* why choose us */}
      <div className='h-max-content w-[100%] p-[2rem] flex items-center justify-center'>
        <div className='h-[100%] w-[84%]'>
          <p className='text-center text-xl font-poppinsRegular font-bold'>Partnering for Success: The Unique Advantages of Choosing Digital Dynamics Marketing Solutions</p>
          <p className='text-center text-md mt-[0.5rem] font-poppinsRegular'>We are relentlessly results-driven. Our commitment goes beyond vanity metrics; we focus on tangible outcomes, whether it's boosting your search engine ranking, increasing social media engagement, or enhancing overall online visibility.</p>

          <div className='mt-[1rem] h-[30%] w-[100%] flex md:flex-row xs:flex-col-reverse items-center justify-between'>
            <div className='md:h-[100%] md:w-[49%] xs:w-[100%] xs:h-[60%]'>
              <p className='font-poppinsRegular'>We're your one-stop-shop for comprehensive digital solutions. From Social Media Marketing to Web Development, Logo Designing, Content Creation, Meta Ads, Google Ads, and SEO – we've got the expertise to boost your online presence.</p>
              <p className='font-poppinsRegular mt-[1rem]'>Our Social Media Marketing strategies are crafted for maximum impact, our Web Development ensures a dynamic online presence, and our Logo Designing creates a memorable brand identity. Content Creation? We excel in producing captivating content, and our Meta Ads and Google Ads campaigns are strategically optimised for results.</p>
              <p className='font-poppinsRegular mt-[1rem]'>With proven SEO strategies, we ensure your business ranks high on search engines. What sets us apart? Customised solutions tailored to your unique business needs, a transparent data-driven approach, and a customer-centric focus that guarantees satisfaction. Choose VittiPanya Digital for a digital marketing journey that's exceptional and results-driven. Let's elevate your brand together!</p>
            </div>
            <div className='md:h-[100%] md:w-[49%] xs:w-[100%] xs:h-[40%]'>
              <img src={aboutUsVector4} alt="vector" className='h-[100%] w-[100%]' />
            </div>
          </div>
        </div>
      </div>

      {/* hire our experts */}
      <div className='h-max-content p-[2rem] bg-[#ffc600] w-[100%]  flex items-center justify-center'>
        <div className='h-[90%] w-[82%] flex md:flex-row xs:flex-col items-center justify-between'>
          <div><p className='text-2xl font-bold font-poppinsRegular'>Give a chance to serve you better </p></div>
          <button className='md:w-[300px] xs:w-[260px] font-poppinsRegular font-bold rounded-md h-[30px] bg-[black] text-[white] md:mt-0 xs:mt-[1rem]'>Hire us</button>
        </div>
      </div>

    </div>
  )
}

export default About