import React from "react";
import { useState } from "react";
import Modal from './Modal';
import {
    Navbar,
    Collapse,
    Typography,
    
    IconButton,
    List,
    ListItem,
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
} from "@material-tailwind/react";
import {
    ChevronDownIcon,
    Bars3Icon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import {
    Bars4Icon,
    GlobeAmericasIcon,
    NewspaperIcon,
    PhoneIcon,
    RectangleGroupIcon,
    SquaresPlusIcon,
    SunIcon,
    TagIcon,
    UserGroupIcon,
} from "@heroicons/react/24/solid";

const navListMenuItems = [
    {
        title: "Website Design & Development",
        description: "Find the perfect solution for your needs.",
        icon: SunIcon,
    },
    {
        title: "App Design & Development (IOS & Android)",
        description: "Meet and learn about our dedication",
        icon: SunIcon,
    },
    {
        title: "Content Marketing",
        description: "Find the perfect solution for your needs.",
        icon: SunIcon,
    },
    {
        title: "Social Media Marketing",
        description: "Learn how we can help you achieve your goals.",
        icon: SunIcon,
    },
    {
        title: "SEO",
        description: "Learn how we can help you achieve your goals.",
        icon: SunIcon,
    },
    {
        title: "Meta Ads",
        description: "Learn how we can help you achieve your goals.",
        icon: SunIcon,
    },
    {
        title: "Google Ads",
        description: "Learn how we can help you achieve your goals.",
        icon: SunIcon,
    },
    {
        title: "Lead Generation",
        description: "Learn how we can help you achieve your goals.",
        icon: SunIcon,
    },
    {
        title: "Youtube Marketing",
        description: "Learn how we can help you achieve your goals.",
        icon: SunIcon,
    },
];

function NavListMenu() {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

    const handleMouseEnter = () => {
        setIsMenuOpen(true);
        setIsMobileMenuOpen(true);
    };

    const handleMouseLeave = () => {
        setIsMenuOpen(false);
        setIsMobileMenuOpen(false);
    };

    const renderItems = navListMenuItems.map(
        ({ icon, title, description }, key) => (
            <a href="#" key={key}>
                <MenuItem className="flex items-center gap-3">
                    <div className="flex items-center justify-center !bg-blue-gray-50 p-2">
                        {React.createElement(icon, {
                            strokeWidth: 2,
                            className: "h-6 text-gray-900 w-6",
                        })}
                    </div>
                    <div>
                        <Typography
                            variant="h6"
                            color="blue-gray"
                            className="flex items-center text-sm font-bold"
                        >
                            {title}
                        </Typography>
                        <Typography
                            variant="paragraph"
                            className="text-xs !font-medium text-blue-gray-500"
                        >
                            {description}
                        </Typography>
                    </div>
                </MenuItem>
            </a>
        ),
    );

    return (
        <React.Fragment>
            <Menu
                open={isMenuOpen}
                handler={setIsMenuOpen}
                offset={{ mainAxis: 20 }}
                placement="bottom"
            >
                <MenuHandler>
                    <Typography
                        as="div"
                        variant="small"
                        className="font-medium"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <ListItem
                            className="flex items-center gap-2 py-8 pr-4 font-medium text-blue-900 font-bold"
                            selected={isMenuOpen || isMobileMenuOpen}
                        >
                            Services
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`hidden h-3 w-3 transition-transform lg:block ${isMenuOpen ? "rotate-180" : ""
                                    }`}
                            />
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`block h-3 w-3 transition-transform lg:hidden ${isMobileMenuOpen ? "rotate-180" : ""
                                    }`}
                            />
                        </ListItem>
                    </Typography>
                </MenuHandler>
                <MenuList
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    className="hidden max-w-screen-xl rounded-xl lg:block"
                >
                    <ul className="grid grid-cols-3 gap-y-2 outline-none outline-0">
                        {renderItems}
                    </ul>
                </MenuList>
            </Menu>
            <div className="block lg:hidden">
                <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
            </div>
        </React.Fragment>
    );
}

function NavList() {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };
    return (
        <>
            <List className="mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1 gap-11">
                <Typography
                    as="a"
                    href="/"
                    variant="small"
                    color="blue-gray"
                    className="font-medium"
                >
                    
                    <ListItem className="flex items-center gap-2 py-8 pr-4 text-blue-900 font-bold">Home</ListItem>



                </Typography>
                <Typography
                    as="a"
                    href="/about-us"
                    variant="small"
                    color="blue-gray"
                    className="font-medium"
                >
                    <ListItem className="flex items-center gap-2 py-8 pr-4 text-blue-900 font-bold">About Company</ListItem>


                </Typography>

                <NavListMenu />
                <Typography
                    as="a"
                    href="/blog"
                    variant="small"
                    color="blue-gray"
                    className="font-medium"
                >
                    <ListItem className="flex items-center gap-2 py-8 pr-4  text-blue-900 font-bold">
                        Blogs
                    </ListItem>
                </Typography>
                <Typography
                    as="a"
                    href="/career"
                    variant="small"
                    color="blue-gray"
                    className="font-medium"
                >
                    <ListItem className="flex items-center gap-2 py-8 pr-4  text-blue-900 font-bold">
                        Career
                    </ListItem>
                </Typography>
                <Typography
                    as="a"
                    href="/contact-us"
                    variant="small"
                    color="blue-gray"
                    className="font-medium"
                >
                    <ListItem className="flex items-center gap-2 py-8 pr-1  text-blue-900 font-bold">
                        Contact Us
                    </ListItem>
                </Typography>
                <button onClick={openModal} className={` h-full bg-[#204093] flex items-center justify-center px-10 py-7 text-lg font-semibold notoSans text-[#fff]  hover:underline`}>Get Quote</button>

            </List>
            <Modal isOpen={modalIsOpen} onClose={closeModal} />
        </>
    );
}

const MegaMenuDefault = () => {
    const [openNav, setOpenNav] = React.useState(false);

    React.useEffect(() => {
        window.addEventListener(
            "resize",
            () => window.innerWidth >= 960 && setOpenNav(false),
        );
    }, []);

    return (
        <Navbar className="mx-auto max-w-screen-xl px-4 py-2 ">
            <div className="flex items-center justify-between text-blue-gray-900">
                <Typography
                    as="a"
                    href="/"
                    variant="h6"
                    className="mr-4 cursor-pointer py-1.5 lg:ml-2 w-24"
                >
                    <img src='https://vittipanya.com/static/media/vittiLogo.12cb687243744c4063df.png' />
                </Typography>
                <div className="hidden lg:block">
                    <NavList />
                </div>
                <IconButton
                    variant="text"
                    color="blue-gray"
                    className="lg:hidden"
                    onClick={() => setOpenNav(!openNav)}
                >
                    {openNav ? (
                        <XMarkIcon className="h-6 w-6" strokeWidth={2} />
                    ) : (
                        <Bars3Icon className="h-6 w-6" strokeWidth={2} />
                    )}
                </IconButton>
            </div>
            <Collapse open={openNav} className="lg:hidden">
                <div className="flex justify-end">
                    <div className="bg-white w-64 shadow-lg">
                        <NavList />
                    </div>
                </div>
            </Collapse>
        </Navbar>
    );
}
export default MegaMenuDefault;


// import React, { useState, useEffect } from "react";
// import Modal from './Modal';
// import {
//     Navbar,
//     Collapse,
//     Typography,
//     IconButton,
//     List,
//     ListItem,
//     Menu,
//     MenuHandler,
//     MenuList,
//     MenuItem,
// } from "@material-tailwind/react";
// import {
//     ChevronDownIcon,
//     Bars3Icon,
//     XMarkIcon,
// } from "@heroicons/react/24/outline";
// import { SunIcon } from "@heroicons/react/24/solid";

// const navListMenuItems = [
//     { title: "Website Design & Development", description: "Find the perfect solution for your needs.", icon: SunIcon },
//     { title: "App Design & Development (IOS & Android)", description: "Meet and learn about our dedication", icon: SunIcon },
//     { title: "Content Marketing", description: "Find the perfect solution for your needs.", icon: SunIcon },
//     { title: "Social Media Marketing", description: "Learn how we can help you achieve your goals.", icon: SunIcon },
//     { title: "SEO", description: "Learn how we can help you achieve your goals.", icon: SunIcon },
//     { title: "Meta Ads", description: "Learn how we can help you achieve your goals.", icon: SunIcon },
//     { title: "Google Ads", description: "Learn how we can help you achieve your goals.", icon: SunIcon },
//     { title: "Lead Generation", description: "Learn how we can help you achieve your goals.", icon: SunIcon },
//     { title: "Youtube Marketing", description: "Learn how we can help you achieve your goals.", icon: SunIcon },
// ];

// function NavListMenu() {
//     const [isMenuOpen, setIsMenuOpen] = useState(false);
//     const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

//     const renderItems = navListMenuItems.map(({ icon: Icon, title, description }, key) => (
//         <a href="#" key={key}>
//             <MenuItem className="flex items-center gap-3">
//                 <div className="flex items-center justify-center bg-blue-gray-50 p-2">
//                     <Icon strokeWidth={2} className="h-6 text-gray-900 w-6" />
//                 </div>
//                 <div>
//                     <Typography variant="h6" color="blue-gray" className="text-sm font-bold">{title}</Typography>
//                     <Typography variant="paragraph" className="text-xs font-medium text-blue-gray-500">{description}</Typography>
//                 </div>
//             </MenuItem>
//         </a>
//     ));

//     return (
//         <>
//             <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom">
//                 <MenuHandler>
//                     <Typography
//                         as="div"
//                         variant="small"
//                         className="font-medium"
//                         onClick={() => setIsMenuOpen(!isMenuOpen)}
//                     >
//                         <ListItem className="flex items-center gap-2 py-2 pr-4 font-medium text-gray-900">
//                             Services
//                             <ChevronDownIcon
//                                 strokeWidth={2.5}
//                                 className={`h-3 w-3 transition-transform ${isMenuOpen ? "rotate-180" : ""}`}
//                             />
//                         </ListItem>
//                     </Typography>
//                 </MenuHandler>
//                 <MenuList className="bg-white">{renderItems}</MenuList>
//             </Menu>
//             <div className="block lg:hidden">
//                 <button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} className="py-2">
//                     <Typography variant="small" className="font-medium text-gray-900">Services</Typography>
//                     <ChevronDownIcon
//                         strokeWidth={2.5}
//                         className={`h-3 w-3 transition-transform ${isMobileMenuOpen ? "rotate-180" : ""}`}
//                     />
//                 </button>
//                 <Collapse open={isMobileMenuOpen}>
//                     <div className="flex flex-col">{renderItems}</div>
//                 </Collapse>
//             </div>
//         </>
//     );
// }

// function NavList() {
//     const [modalIsOpen, setModalIsOpen] = useState(false);
//     const openModal = () => setModalIsOpen(true);
//     const closeModal = () => setModalIsOpen(false);

//     return (
//         <>
//             <List className="mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1 gap-5">
//                 {["Home", "About Company", "Blogs", "Career", "Contact Us"].map((item, index) => (
//                     <Typography
//                         key={index}
//                         as="a"
//                         href={`/${item.toLowerCase().replace(" ", "-")}`}
//                         variant="small"
//                         color="blue-gray"
//                         className="font-medium"
//                     >
//                         <ListItem className="flex items-center gap-2 py-2 pr-4 text-black">{item}</ListItem>
//                     </Typography>
//                 ))}
//                 <NavListMenu />
//                 <button
//                     onClick={openModal}
//                     className="h-full bg-[#204093] flex items-center justify-center px-5 py-2 text-lg font-semibold text-white hover:underline"
//                 >
//                     Get Quote
//                 </button>
//             </List>
//             <Modal isOpen={modalIsOpen} onClose={closeModal} />
//         </>
//     );
// }

// const MegaMenuDefault = () => {
//     const [openNav, setOpenNav] = useState(false);

//     useEffect(() => {
//         const handleResize = () => {
//             if (window.innerWidth >= 960) setOpenNav(false);
//         };
//         window.addEventListener("resize", handleResize);
//         return () => window.removeEventListener("resize", handleResize);
//     }, []);

//     return (
//         <Navbar className="mx-auto max-w-screen-xl px-4 py-2">
//             <div className="flex items-center justify-between text-blue-gray-900">
//                 <Typography as="a" href="/" variant="h6" className="mr-4 cursor-pointer py-1.5 lg:ml-2 w-24">
//                     <img src='https://vittipanya.com/static/media/vittiLogo.12cb687243744c4063df.png' alt="Logo" />
//                 </Typography>
//                 <div className="hidden lg:block">
//                     <NavList />
//                 </div>
//                 <IconButton
//                     variant="text"
//                     color="blue-gray"
//                     className="lg:hidden"
//                     onClick={() => setOpenNav(!openNav)}
//                 >
//                     {openNav ? <XMarkIcon className="h-6 w-6" strokeWidth={2} /> : <Bars3Icon className="h-6 w-6" strokeWidth={2} />}
//                 </IconButton>
//             </div>
//             <Collapse open={openNav} className="lg:hidden">
//                 <div className="flex justify-end">
//                     <div className="bg-white w-64 shadow-lg">
//                         <NavList />
//                     </div>
//                 </div>
//             </Collapse>
//         </Navbar>
//     );
// }

// export default MegaMenuDefault;
