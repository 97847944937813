import React, { useEffect, useState } from 'react';

const TypingEffect = ({ text, color }) => {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    let index = 0;

    const typingInterval = setInterval(() => {
      if (index <= text.length) {
        setDisplayedText(text.slice(0, index));
        index += 1;
      } else {
        clearInterval(typingInterval);
      }
    }, 10); // Adjust the interval as needed

    return () => clearInterval(typingInterval); // Cleanup on component unmount
  }, [text]);

  return <span style={{ color: color, fontWeight: 400 }}>{displayedText}</span>;
};

export default TypingEffect;
