import React from 'react'
import HeroSection from '../component/HeroSection'
import careerimg from '../assets/Herosection/hero-career-img.png'

function Careers() {
    return (
        <>
            <HeroSection heading="Careers" subheading="Join Our Dynamic Team: Unlock Your Potential in the World of Tech Innovation" heroImg={careerimg} />
        </>
    )

}


export default Careers