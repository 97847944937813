import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Blog from "./pages/Blog";
import Careers from "./pages/Careers";
import Services from "./pages/Services";
import NotFound from "./pages/NotFound";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/services" element={<Services />} />
      <Route path="/career" element={<Careers />} />
      <Route path="/contact-us" element={<Contact />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
