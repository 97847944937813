import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../component/CustomButton';

const NotFound = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            navigate('/');
        }, 3000);

        return () => clearTimeout(timeoutId);
    }, [navigate]);

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
            <h1 className="text-6xl font-bold text-[#4364ad]">404</h1>
            <p className="mt-4 text-xl text-gray-700">Oops! The page you are looking for does not exist.</p>
            <CustomButton
                text="Back to Home"
                className="text-white bg-[#4364ad] hover:bg-[#1d1d1d] focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 w-[150px] text-center mb-2"
                onClick={() => navigate('/')}
            />
        </div>
    );
};

export default NotFound;
