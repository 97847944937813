import React from 'react'
import blogimg from '../assets/Herosection/hero-blog-img.png'
import HeroSection from '../component/HeroSection';

const Blog = () => {
  return (
    <HeroSection heading="Blog" subheading="Insights, Trends, and Expertise: Stay Ahead in the Digital Landscape with Our Blog" heroImg={blogimg} />
  )
}

export default Blog