import React from 'react'
import './IndustriesCard.css';



const IndustriesCard = ({ img, heading }) => {
    return (
        <div className='industries-card'>
            <img src={img} />
            <h1>{heading}</h1>

            
        </div>
    )
}

export default IndustriesCard