import React from 'react'
import HeroSection from '../component/HeroSection'
import servicesimg from '../assets/Herosection/hero-services-img.png'

function Services() {
    return (
        <div>
            <HeroSection heading="Services" subheading="Explore Our Comprehensive Suite of IT Services: Tailored Solutions for Your Business Needs" heroImg={servicesimg} />
        </div>
    )
}

export default Services