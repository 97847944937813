import React from 'react';

function CustomButton({ text, className, type }) {
    return (
        <button type={type} className={className}>
            {text}
        </button>
    );
}

export default CustomButton;